<template>
    <div>
        <template v-if="!isLoading && data.flag === 0">
            <div class="d-flex justify-content-end">
                <b-button v-if="data.detail.length" @click="prosesPembelian" variant="pgiBtn" class="mx-1">
                    <i class="fa fa-check"></i> Proses Pembelian
                </b-button>
                <b-button @click="openModalImportSingle" variant="pgiBtn" class="mx-1">
                    <i class="fa fa-file-excel-o"></i> Kirim Satu Cabang
                </b-button>
                <b-button variant="pgiBtn" class="mx-1">
                    <i class="fa fa-sitemap"></i> Kirim Banyak Cabang
                </b-button>
            </div>
        </template>
        <template v-if="role === 'GM General Affair'">
            <div class="d-flex justify-content-end">
                <b-button v-if="data.status_approval === 2" @click="handleApprovePembelian" variant="pgiBtn" class="mr-2">
                    <i class="fa fa-check"></i>
                    Approve
                </b-button>
                <b-button v-if="data.status_approval > 2" @click="$bvModal.show('m-history-approve')" variant="pgiBtn"
                    class="mr-2">
                    <i class="fa fa-history"></i>
                    History Approval
                </b-button>
            </div>
        </template>
        <template v-if="role === 'GM Support'">
            <div class="d-flex justify-content-end">
                <b-button v-if="data.status_approval === 3" @click="handleApprovePembelian" variant="pgiBtn" class="mr-2">
                    <i class="fa fa-check"></i>
                    Approve
                </b-button>
                <b-button v-if="data.status_approval > 2" @click="$bvModal.show('m-history-approve')" variant="pgiBtn"
                    class="mr-2">
                    <i class="fa fa-history"></i>
                    History Approval
                </b-button>
            </div>
        </template>
        <template v-if="role === 'Wakil Direktur'">
            <div class="d-flex justify-content-end">
                <b-button v-if="data.status_approval === 4" @click="handleApprovePembelian" variant="pgiBtn" class="mr-2">
                    <i class="fa fa-check"></i>
                    Approve
                </b-button>
                <b-button v-if="data.status_approval > 2" @click="$bvModal.show('m-history-approve')" variant="pgiBtn"
                    class="mr-2">
                    <i class="fa fa-history"></i>
                    History Approval
                </b-button>
            </div>
        </template>
        <template v-if="role === 'General Affair'">
            <div class="d-flex justify-content-end">
                <b-button v-if="data.status_approval > 1" @click="$bvModal.show('m-history-approve')" variant="pgiBtn"
                    class="mr-2">
                    <i class="fa fa-history"></i>
                    History Approval
                </b-button>
            </div>
        </template>
        <b-table small bordered hover show-empty :isBusy="isLoading" :items="data.detail" :fields="fields" responsive
            class="my-3">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading data...</strong>
                </div>
            </template>
            <template v-slot:empty> Data tidak ditemukan! </template>
            <template v-slot:cell(cabang)="row">
                {{ row.item.cabang || "PUSAT" }}
            </template>
            <template v-slot:cell(nomer_barang)="row">
                <div>
                    <span class="d-block">Kode : {{ row.item.tipe_barang.kode_barang }}</span>
                    <small class="d-block">Nama : {{ row.item.tipe_barang.tipe }}</small>
                </div>
            </template>
            <template v-slot:cell(jumlah)="row">
                <div>
                    <b class="mr-1">{{ row.item.jumlah }}</b>
                    <small>{{ row.item.satuan }}</small>
                </div>
            </template>
            <template v-slot:cell(harga)="row">
                <div class="d-flex align-items-center justify-content-between">
                    <div>Rp.</div>
                    <div class="text-end">{{ $helper.rupiah(row.item.harga || 0) }}</div>
                </div>
            </template>
            <template v-slot:cell(total_harga)="row">
                <div class="d-flex align-items-center justify-content-between">
                    <div>Rp.</div>
                    <div class="text-end">{{ $helper.rupiah(row.item.total_harga || 0) }}</div>
                </div>
            </template>
            <template v-slot:cell(action)="row">
                <div class="text-right">
                    <button :disabled="role == 'General Affair' || data.status_approval <= 2"
                        @click="handleDeleteItem(row.item)" class="btn text-danger">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
            <template v-slot:head(action)="row">
                <div class="text-right">
                    Aksi
                </div>
            </template>
        </b-table>

        <!-- IMPORT MODAL BULK SINGLE -->
        <b-modal id="m-import-single-cabang" size="md" centered hide-footer title="Import Bulk Satu Cabang">
            <b-form @submit.prevent="uploadPembelianSingleCabang">
                <b-form-group label="Pilih file">
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <i class="fa fa-file-excel-o"></i>
                        </b-input-group-prepend>
                        <b-form-file @change="handleChangeFile"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            placeholder="Pilih file" drop-placeholder="Drop file here..."></b-form-file>
                    </b-input-group>
                </b-form-group>
                <div class="d-flex justify-content-end">
                    <b-button :disabled="isLoading" type="submit" variant="pgiBtn">Submit</b-button>
                </div>
            </b-form>
            <a href="/templates/template-product-single-cabang.xlsx">Download template</a>
        </b-modal>



        <!-- MODAL HISTORY APPROVE -->
        <b-modal id="m-history-approve" hide-footer title="History Approval">
            <ul class="list-group list-group-flush">
                <li v-for="(item, index) in history_approval"
                    class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                        <div class="mr-2">{{ index + 1 }}.</div>
                        <div>
                            <div>{{ item.keterangan }}</div>
                            <small>Pada {{ $moment(item.created_at).format('DD MMM YYYY, h:mm:ss') }}</small>
                        </div>
                    </div>
                    <span class="badge bg-success rounded-pill">
                        <i class="fa fa-check"></i>
                    </span>
                </li>
            </ul>
        </b-modal>

        <!-- <pre>
        {{ data.detail }}
       </pre> -->
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axiosClient from '../../config/api';
export default {
    props: ['data'],
    data() {
        return {
            fields: [
                { key: "cabang", label: "Cabang" },
                { key: "nomer_barang", label: "Info Barang" },
                { key: "jumlah", label: "Jumlah" },
                { key: "harga", label: "Harga" },
                { key: "total_harga", label: "Total Harga" },
                { key: "action", label: "Aksi" },
            ],
            file_single_cabang: null,
            file_multi_cabang: null,
            role: localStorage.getItem('role'),
        };
    },
    methods: {
        async dispatchPembelian() {
            await this.$store.dispatch("getDetailPembelian", this.$route.params.id);
        },
        openModalImportSingle() {
            this.$bvModal.show("m-import-single-cabang");
        },
        // CHANGE FILE
        async handleChangeFile(value) {
            this.file_single_cabang = {
                file: value.target.files[0],
                id_pembelian: this.$route.params.id
            };
        },
        async uploadPembelianSingleCabang() {
            this.$swal({
                title: "Konfirmasi",
                text: "Pastikan file pembelian sudah sesuai!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Proses",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await this.$store.dispatch("uploadPembelianSingleCabang", this.file_single_cabang);
                    if (response.status === 200) {
                        this.dispatchPembelian();
                        this.$bvModal.hide("m-import-single-cabang");
                        this.$helper.toastSucc(this, "Sukses upload data");
                    }
                    else {
                        this.$helper.toastErr(this, "Gagal upload data");
                    }
                }
            });
        },
        async prosesPembelian() {
            this.$swal({
                title: "Proses Pembelian",
                text: "Pastikan data pembelian sudah sesuai!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Proses",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.$route.params.id,
                        flag: 1,
                    };
                    const response = await this.$store.dispatch("prosesPembelian", form);
                    if (response.status === 200) {
                        this.dispatchPembelian();
                        this.$helper.toastSucc(this, "Sukses proses pembelian");
                    }
                    else {
                        this.$helper.toastErr(this, "Gagal proses pembelian");
                    }
                }
            });
        },
        handleDeleteItem(value) {
            this.$swal({
                title: "Konfirmasi",
                text: "Anda akan mengapus!",
                type: "danger",
                showCancelButton: true,
                confirmButtonColor: "#f86c6b",
                cancelButtonText: "Batal",
                confirmButtonText: "Proses",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await axiosClient({
                        url: 'transaksi/pembelian/detail/delete/' + value.id,
                        method: 'DELETE'
                    });
                    if (response.status === 200) {
                        this.dispatchPembelian();
                        this.$helper.toastSucc(this, "Sukses hapus item");
                    }
                    else {
                        this.$helper.toastErr(this, "Gagal hapus item");
                    }
                }
            });
        },
        // PROSES UPDATE
        async handleApprovePembelian() {
            this.$swal({
                title: "Konfirmasi",
                text: "Apakah Anda akan menyetujui pembelian?",
                type: "danger",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Proses",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await axiosClient({
                        method: "POST",
                        url: 'transaksi/pembelian/approve-pembelian',
                        data: {
                            id_pembelian: this.$route.params.id
                        }
                    });
                    if (response.status === 200) {
                        this.dispatchPembelian();
                        this.$store.dispatch("getHistoryApproval", this.$route.params.id);
                        this.$helper.toastSucc(this, "Sukses memproses data");
                    }
                    else {
                        this.$helper.toastErr(this, "Gagal memproses data");
                    }
                }
            });
        }
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        history_approval() {
            return this.$store.state.pembelian.history_approval;
        }
    },
}
</script>